import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./Higher.style";
import { Player } from "video-react";
const Higher: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");

  return (
    <>
      <section className={classes.container}>
        <div className="video">
          {/*  <img
            className={classes.imagePrincipal}
            src={SCREEN_PRINCIPAL_VIDEO}
          /> */}
          <Player
            loop={true}
            playsInline
            muted
            autoPlay={true}
            src={
              !isMobile
                ? "https://storage.googleapis.com/rokinapp-images/videos/videoPortadaSoluciones_desktop.mp4"
                : "https://storage.googleapis.com/rokinapp-images/videos/videoPortadaSoluciones_mobile.mp4"
            }
          />
        </div>
        <div className={classes.title}>
          <h1 className="container">
            <span className="hight">Un</span>
            <div className="medium">
              Personal Trainer
              <br /> Financiero
            </div>

            <span className="low">
              Para que puedas enfocarte en lo <br />
              que <span>realmente</span> importa
            </span>
            {/* <IconButton className={classes.icon}>
              <KeyboardArrowDown onClick={() => {}} />
            </IconButton> */}
          </h1>
        </div>
      </section>
    </>
  );
};

export default Higher;
