import React from "react";
import { BANNER_ICON } from "@images";
import { makeStyles, useMediaQuery } from "@material-ui/core";

const DiscoverRokin: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");

  return (
    <div className={classes.general}>
      <img src={BANNER_ICON} />
    </div>
  );
};

export default DiscoverRokin;

const useStyles = makeStyles((theme) => ({
  general: {
    "& img": {
      width: "100%",
    },
  },
}));
