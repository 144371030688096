import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Montserrat",
    color: "white",
    position: "relative",
    "& video": {
      position: "relative",
      zIndex: 9,
      width: "100%",
      marginBottom: "8.75rem",
      [theme.breakpoints.down(1440)]: {
        marginBottom: "0rem",
      },
    },
    "& .video >div": {
      width: "100%",
      paddingTop: "0!important",
      [theme.breakpoints.down(1440)]: {
        height: "100%",
      },
      "& .video-react-button, .video-react-control-bar": {
        display: "none",
      },
    },
  },
  imagePrincipal: {
    position: "absolute",
    top: "-50px",
    zIndex: 1,
  },
  title: {
    position: "absolute",
    top: "6rem",
    width: "100%",
    margin: "0 auto",
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    "& .container": {
      background: "rgba(8, 69, 84, 0.6)",
      width: "43rem",
      padding: "4rem 5rem",
      borderRadius: 40,
      [theme.breakpoints.down(1440)]: {
        height: "15rem",
        width: "100%",
        top: "1rem",
        borderRadius: 0,
        background: "rgba(0, 0, 0, 0.8)",
      },
    },
    [theme.breakpoints.down(1440)]: {},
    "& .medium": {
      fontSize: "2.875rem",
      fontWeight: "bold",
      color: theme.palette.text.hint,
      [theme.breakpoints.down(1440)]: {
        fontSize: "1.5rem",
        width: "18rem",
      },
    },
    "& .low": {},
    "& span": {
      display: "block",
      fontSize: "2rem",
      [theme.breakpoints.down(1440)]: {
        fontSize: "1rem",
        width: "18rem",
      },
      "& span": {
        fontWeight: "bold",
        display: "contents",
      },
    },
  },
  icon: {
    position: "absolute",
    bottom: "-8rem",
    width: "100%",
    justifyContent: "center",
    left: 0,
    "& svg": {
      color: "white",
      width: "7rem",
      height: "7rem",
    },
  },
}));
