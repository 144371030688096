import React from 'react';
import { useStyles } from './ManageFinances.styles';
import {
  ADMINISTRATION_MOCKUP_DESKTOP,
  BUILB_ICON,
  CLOCK_ICON,
  COOLEBRA_ICON2,
  COOLEBRA_MOCKUP,
  FINANCIAL_PERSONAL_ICON,
  INVOICE_DOLLAR_ICON,
  MONEY_ICON,
  SAFEBOX_ICON,
  SOLUTION_2_MOCKUP,
  SOLUTION_3_MOCKUP,
  SOLUTION_ICON_1,
  SOLUTION_ICON_2,
  SOLUTION_ICON_3,
  SOLUTION_ICON_4,
  SUPPORT_ICON,
  TALK_BUBBLE_ICON,
  TRENDUP_ICON,
} from '@images';
import { useMediaQuery } from '@material-ui/core';
import {
  AUTOMATIC_ICON,
  BILL_SOLUTIONS_ICON,
  CREDITCARD_ICON,
  DISCOUNT_ICON,
  PIECHART_ICON,
  ROCKET_ICON,
  SETTINGS_LINES_ICON,
  STATISTICS_ICON,
  USER_ICON,
  WALLET_ICON,
} from 'images/GoogleCloud';

const ManageFinances: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1440px)');

  const personalT = [
    {
      icon: SOLUTION_ICON_2,
      img: ADMINISTRATION_MOCKUP_DESKTOP,
      color: classes.color1,
      classTitle: classes.title,
      title: (
        <>
          Administra
          {!isMobile && <br />} tus finanzas
        </>
      ),
      classSubtitle: classes.subtitle,
      subtitle: (
        <span>
          Crea tu <span>presupuesto</span> y obtén consejos en base a{' '}
          {!isMobile && <br />} tus gastos en tu <span>Portal Financiero.</span>
        </span>
      ),
      icons: [
        {
          icon: STATISTICS_ICON,
          text: (
            <>
              Registra tus <br /> ingresos y gastos
            </>
          ),
          force: true,
        },
        {
          icon: BILL_SOLUTIONS_ICON,
          text: (
            <>
              Simula <br /> presupuestos
            </>
          ),
          force: true,
        },
        {
          icon: CREDITCARD_ICON,
          text: (
            <>
              Maneja tus <br />
              deudas y créditos
            </>
          ),
          force: true,
        },
        {
          icon: PIECHART_ICON,
          text: (
            <>
              Conoce tus <br />
              hábitos
            </>
          ),
          force: true,
        },
      ],
    },
    {
      icon: SOLUTION_ICON_3,
      img: SOLUTION_2_MOCKUP,
      color: classes.color1,
      classTitle: classes.title,
      imageMobile: isMobile && classes.imageMobile,
      title: 'Conoce el mundo financiero',
      classSubtitle: classes.subtitle,
      subtitle: (
        <span>
          <span>Juega</span> para completar misiones y ganar<span> Roks</span>,
          mientras
          {!isMobile && <br />}
          descubres herramientas para{' '}
          <span>
            mejorar tu situación financiera {!isMobile && <br />} y manejar tu
            plata
          </span>{' '}
          con la <span>Rokipedia.</span>
        </span>
      ),
      icons: [
        {
          icon: SETTINGS_LINES_ICON,
          text: (
            <>
              Encuentra los productos
              {!isMobile && <br />} para tus necesidades
            </>
          ),
          force: false,
        },
        {
          icon: USER_ICON,
          text: (
            <>
              Descubre tu perfil
              <br /> de inversionista
            </>
          ),
          force: true,
        },
        {
          icon: ROCKET_ICON,
          text: (
            <>
              Sácale provecho a los
              <br /> instrumentos de ahorro
            </>
          ),
          force: false,
        },
        {
          icon: CREDITCARD_ICON,
          text: (
            <>
              Entiende tus <br />
              créditos y deudas
            </>
          ),
          force: true,
        },
      ],
    },
    {
      icon: SOLUTION_ICON_1,
      img: SOLUTION_3_MOCKUP,
      color: classes.color1,
      imageMobile: isMobile && classes.imageMobile,
      classTitle: classes.title,
      title: <>Ahorra e {!isMobile && <br />} invierte</>,
      classSubtitle: classes.subtitle,
      subtitle: (
        <span>
          Pon en práctica tu <span>planificación y aprendizaje:</span> <br />
          presupuesta tus objetivos, simplifica tu ahorro
          <br />y alcanza tus metas.
        </span>
      ),
      icons: [
        {
          icon: INVOICE_DOLLAR_ICON,
          text: (
            <>
              Organiza y presupuesta
              <br /> tus objetivos
            </>
          ),
          force: false,
        },
        {
          icon: CLOCK_ICON,
          text: (
            <>
              Automatiza <br />
              tu inversión
            </>
          ),
          force: true,
        },
        {
          icon: SAFEBOX_ICON,
          text: <>Utiliza herramientas de microahorro</>,
          force: false,
        },
        {
          icon: TRENDUP_ICON,
          text: (
            <>
              Proyecta <br />
              tus ahorros
            </>
          ),
          force: true,
        },
      ],
    },
    {
      icon: SOLUTION_ICON_4,
      img: FINANCIAL_PERSONAL_ICON,
      imageMobile: isMobile && classes.imageMobile,
      color: classes.color1,
      specialClass: !isMobile && classes.classThreeElement,
      classTitle: classes.title,
      title: (
        <>
          Tu asistente
          <br /> personal financiero
        </>
      ),
      classSubtitle: classes.subtitle,
      subtitle: (
        <span>
          Recibe <span>apoyo y orientación personalizado</span> para
          {!isMobile && <br />} resolver tus problemas y dudas financieras.
        </span>
      ),
      icons: [
        {
          icon: TALK_BUBBLE_ICON,
          text: (
            <>
              Organiza y presupuesta
              <br /> tus objetivos
            </>
          ),
          force: false,
        },
        {
          icon: SUPPORT_ICON,
          text: (
            <>
              Automatiza <br />
              tu inversión
            </>
          ),
          force: true,
        },
        {
          text: <></>,
          force: true,
        },
        {
          icon: BILL_SOLUTIONS_ICON,
          text: (
            <>
              Encuentra
              <br /> seguros y créditos <br />
              convenientes
            </>
          ),
          force: false,
        },
        {
          icon: MONEY_ICON,
          text: (
            <>
              Descubre si puedes
              <br /> pagar menos por <br />
              tus productos
            </>
          ),
          force: true,
        },
        {
          icon: BUILB_ICON,
          text: (
            <>
              Entiende <br /> cómo invertir
            </>
          ),
          force: false,
        },
      ],
    },
    {
      icon: COOLEBRA_ICON2,
      img: COOLEBRA_MOCKUP,
      imageMobile: isMobile && classes.imageMobile,
      color: classes.color1,
      classTitle: classes.title,
      title: (
        <>
          Descubre los <br />
          descuentos de
          <br /> tus productos
        </>
      ),
      classSubtitle: classes.subtitle,
      subtitle: (
        <span>
          Encuentra en <span>un solo lugar</span> todos los{' '}
          <span>
            descuentos y beneficios
            {!isMobile && <br />} de tus tarjetas,
          </span>{' '}
          gracias a nuestra alianza con <span>Coolebra</span>
        </span>
      ),
      icons: [
        {
          icon: DISCOUNT_ICON,
          text: (
            <>
              Revisa tus descuentos
              <br /> automáticamente
            </>
          ),
          force: false,
        },
        {
          icon: SETTINGS_LINES_ICON,
          text: (
            <>
              Filtra según
              <br /> tus intereses
            </>
          ),
          force: true,
        },
        {
          icon: AUTOMATIC_ICON,
          text: (
            <>
              Ahorra sin cambiar
              <br /> tus hábitos
            </>
          ),
          force: true,
        },
        {
          icon: WALLET_ICON,
          text: (
            <>
              Ve tus beneficios en
              <br /> un solo lugar
            </>
          ),
          force: false,
        },
      ],
    },
  ];

  return (
    <section className={classes.container}>
      <div>
        {personalT.map((info, i) => (
          <>
            <section
              key={info.classTitle}
              className={`${classes.generalContainer} `}
              id={`s${i}`}
            >
              <div className={classes.top}>
                <div className={classes.topTitle}>
                  <img src={info.icon} />
                  <h2 className={classes.title}>{info.title}</h2>
                </div>
                <div className={classes.subtitle}>{info.subtitle}</div>
              </div>
              <div className={`image ${info.imageMobile}`}>
                <img src={info.img} />
              </div>
            </section>
            <section className={`${classes.cardicon} ${info.color}`}>
              <div className="limitWidth">
                <div className={`containericon  ${info.specialClass}`}>
                  {info.icons.map((icon) => (
                    <div
                      className={`${classes.containerIcon} `}
                      key={icon.text}
                    >
                      <section key={icon.text} className={`${classes.icon}`}>
                        {icon.icon && <img src={icon.icon} />}
                        <span className={classes.iconText}>{icon.text}</span>
                      </section>
                    </div>
                  ))}
                </div>{' '}
              </div>
            </section>
          </>
        ))}
      </div>
    </section>
  );
};

export default ManageFinances;
