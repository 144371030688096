import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  generalContainer: {
    display: "flex",
    gap: "4rem",
    justifyContent: "start",
    position: "relative",
    zIndex: 9,
    width: "80%",
    maxWidth: "1200px",
    margin: "14rem auto 0",
    "& .image": {
      position: "absolute",
      top: "-2rem",
      width: "100%",
      display: "flex",
      justifyContent: "end",
      [theme.breakpoints.down(1440)]: {
        top: "17rem",
        justifyContent: "center",
      },
      "& img": {
        maxWidth: "40rem",
        [theme.breakpoints.down(1440)]: {
          maxWidth: "30rem",
        },
      },
    },

    [theme.breakpoints.down(1440)]: {
      display: "grid",
      width: "100%",
      margin: "0 auto",
      justifyContent: "center",
    },
    "& img": {
      maxWidth: "40rem",
      [theme.breakpoints.down(1440)]: {
        maxWidth: "90%",
        margin: "0 auto",
      },
    },
  },
  imageMobile: {
    "& img": {
      width: "22rem",
    },
    top: "20rem!important",
  },
  container: {
    paddingTop: "0rem",
    justifyContent: "center",
  },
  button: {},
  cardicon: {
    padding: "2.5rem 2.5rem 6rem",
    margin: "4rem 0 0rem 0",
    overflowX: "scroll",
    width: "100%",
    position: "relative",
    "& .limitWidth": {
      [theme.breakpoints.up(1440)]: {
        width: "87%",
        margin: "0 auto",
        maxWidth: "1280px",
      },
    },
    "& .containericon": {
      width: "40rem",
      display: "flex",
      gap: "2rem",
      textAlign: "center",
      flexWrap: "wrap",
      justifyContent: "start",
      [theme.breakpoints.down(1440)]: {
        width: "100%",
        flexWrap: "nowrap",
        overflowX: "scroll",
        margin: 0,
        gap: "0rem",
      },
    },

    [theme.breakpoints.down(1240)]: {
      padding: "7rem 1.5rem 4rem",
      margin: "10rem 0 0",
    },
    [theme.breakpoints.up(1240)]: {
      justifyContent: "center",
    },
  },
  icon: {
    position: "relative",
    display: "grid",
    "& img": {
      width: "2rem",
      margin: "0 auto",
      height: "3rem",
    },
  },
  principal1: {},
  principal2: {},
  principal3: {},
  title: {
    fontFamily: "Montserrat",
    fontSize: "2.25rem",
    fontWeight: 600,
    lineHeight: "normal",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1440)]: {
      fontSize: "1.5rem",
      width: "18rem",
    },
  },
  topTitle: {
    display: "flex",
    gap: "2rem",
    alignItems: "start",
    [theme.breakpoints.down(1440)]: {
      justifyContent: "center",
      width: "20rem",
      margin: "0 auto",
      gap: "1.8rem",
    },
    "& img": {
      width: "4.5rem",
      [theme.breakpoints.down(1440)]: {
        paddingTop: "0rem",
        width: "3.3rem",
      },
    },
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.35rem",
    fontWeight: 300,
    width: "34rem",
    color: theme.palette.text.secondary,
    paddingTop: "2rem",
    [theme.breakpoints.down(1440)]: {
      justifyContent: "center",
      width: "19rem",
      fontSize: "0.875rem",
      margin: "0 auto",
    },
    "& >span >span": {
      fontWeight: "bold",
    },
  },
  classThreeElement: {
    display: "grid!important",
    gridTemplateColumns: "30% 30% 30%",
  },
  top: {
    width: "30rem",
    [theme.breakpoints.down(1440)]: {
      margin: "5rem auto 7rem",
      width: 375,
    },
  },
  txt: {},
  header: {},
  forceEnter: {},
  color3: {
    background: "#ba941e",
  },
  color2: {
    background: "#4619b6",
  },
  color1: {
    background: "#00323c",
  },
  box: {
    width: "100%",
    height: "20rem",
    position: "absolute",
    top: -60,
    left: 0,
    zIndex: 1,
  },
  iconText: {
    color: "white",
    fontSize: "1.226rem",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    minWidth: "10rem",
    [theme.breakpoints.down(1440)]: {
      fontSize: "0.75rem",
    },
  },
  containerIcon: {
    alignItems: "start",
    width: 220,
  },
}));
