import React from "react";
import { PageProps } from "gatsby";
import { makeStyles } from "@material-ui/core";
import { LayoutBild, SEO } from "@components";
import Higher from "@components/OurSolutions/Higher/Higher";
import PersonalTrainer from "@components/OurSolutions/ManageFinances/ManageFinances";
import DiscoverRokin from "@components/PageLanding/DiscoverRokin";

const SaveByRokinPage: React.FC<PageProps> = () => {
  const classes = useStyles();

  return (
    <>
      <SEO
        title="Toma el control de tus finanzas personales - Rokin"
        description="Monitorea y clasifica tus gastos, crea presupuestos, aprende sobre el mundo financiero, invierte en tus objetivos y potencia tu ahorro con Rokin."
      />
      <LayoutBild ourSolutions key="Bottom">
        <Higher />
        <PersonalTrainer />
        <DiscoverRokin />
        {/* <Contact /> */}
      </LayoutBild>
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({}));
